












































import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import ImgCard from '@/components/imgCard.vue';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import { StorageServices } from '@/services/StorageServices'
import * as VM from '@/viewModel';
import * as OM from '@/model';
import CallToButton from '@/components/callToButton.vue';
import { AssetClient, OrdineClient } from '@/services/Services';

@Component({
    components: {
        ImgCard,
        AssetTypeIcon,
        CallToButton
    }
})
export default class AssetsSwitch extends Vue {

    types: string[] = []
    filterVm: OM.AssetFilterVm = new OM.AssetFilterVm();
    
    beforeRouteEnter(to, from, next){
        let filterVm = new OM.AssetFilterVm();
        filterVm.portafoglioIdentifier = to.params.portafoglioIdentifier;
        AssetClient.getAssetClasses(filterVm)
        .then(data => {
            next((vc: AssetsSwitch) => {
                vc.types = data;
                filterVm.assetType = "All";
                vc.filterVm = filterVm;
                vc.filterVm.portafoglioIdentifier = "";
            });
        })
    }

    filterByType(val){
        this.filterVm.assetType = val;
        this.$router.push('assets/' + this.filterVm.assetType);
    }

    slideAsset(item){
        Vue.set(item, 'selected', !item.selected);
    }

    deleteOrder(item, i){
        store.state.creaSwitchVm.ordiniAcquisto.splice(i, 1);
        StorageServices.setCreaSwitch(store.state.creaSwitchVm);
    }
    
    saveSwitch(){
        OrdineClient.creaSwitch(store.state.creaSwitchVm)
        .then(x => {
            store.state.creaSwitchVm = null;
            StorageServices.setCreaSwitch(null);
            StorageServices.deletePortafoglioStatus(this.$route.params.portafoglioIdentifier)
            this.$router.go(-2);
        })
    }

}

